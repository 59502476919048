export function loading() {
  const loading = document.querySelector('.js-loading');

  if (!loading) {
    return false;
  }

  const kv = document.querySelector('.js-kv');

  // 初回アクセスかどうかを判定
  if (!sessionStorage.getItem('visited')) {
    window.addEventListener('load', () => {
      loading.classList.add('is-animated');

      setTimeout(() => {
        kv.classList.add('is-animated');
      }, 2200);

      setTimeout(() => {
        loading.style.display = "none";
      }, 3000);

      // 訪問済みフラグをセット
      sessionStorage.setItem('visited', 'true');
    });
  } else {
    // 2回目以降はローディングを非表示にする
    loading.style.display = "none";
    kv.classList.add('is-animated');
  }

}