import { loading } from './modules/loading';
import { scrollmagic } from './modules/scrollmagic';
import { bnr } from './modules/bnr';
import { hamburger } from './modules/hamburger';
import { accordion } from './modules/accordion';
import { smooth } from './modules/smooth';
import { formDate } from './modules/formDate';
import { globalMenu } from './modules/globalMenu';
import { moveTxt } from './modules/moveTxt';

loading();
scrollmagic();
bnr();
hamburger();
accordion();
smooth();
formDate();
globalMenu();
moveTxt();
