export function hamburger() {
  const hamburger = document.querySelector('.js-hamburger');

  if(!hamburger) return;

  const headerBar = document.querySelector('.js-headerBar');
  const hamburgerMenu = document.querySelector('.js-hamburgerMenu');

  const menuOpen = () => {
    hamburger.classList.toggle('is-active');
    headerBar.classList.toggle('is-active');
    hamburgerMenu.classList.toggle('is-view');
  }

  hamburger.addEventListener('click', () => {
    menuOpen();
  });
}