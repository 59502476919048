export function bnr() {
  const bnr = document.querySelector('.js-bnr');

  if (!bnr) return;
  
  const bnrClose = bnr.querySelector('.js-bnrClose');
  const footer = document.querySelector('.js-footer');

  let ticking = false;

  window.addEventListener('scroll', () => {
    if(!ticking) {
      requestAnimationFrame(() => {
        let scrollTop = window.scrollY;
        let footerY = footer.getBoundingClientRect().top + window.pageYOffset;

        if(scrollTop > 0 && scrollTop + window.innerHeight < footerY) {
          bnr.classList.add('is-view');
        } else {
          bnr.classList.remove('is-view');
        }

        ticking = false;
      });
      ticking = true;
    }
  });

  bnrClose.addEventListener('click', () => {
    bnr.remove();
  });
}