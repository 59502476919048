export function moveTxt() {
  const moveTxt = document.querySelector('.js-moveTxt');

  if(!moveTxt) return;

  const startPos = 0;
  const endPos = -3000;

  function updateSlide() {
    const scrollY = window.scrollY;
    const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
    const slowFactor = 0.5;
    const progress = (scrollY / maxScroll) * slowFactor;

    // 右端から左端へスライドする
    const translateX = startPos - (startPos - endPos) * progress;
    moveTxt.style.transform = `translateX(${translateX}px)`;

    requestAnimationFrame(updateSlide);
  }

  updateSlide();
}