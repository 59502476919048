export function globalMenu() {
  const globalMenu = document.querySelector('.js-globalMenu');
  if (!globalMenu) return;

  const header = document.querySelector('.js-header');
  const globalMenuTrigger = header.querySelectorAll('.js-globalMenuTrigger');
  const globalMenuArea = globalMenu.querySelector('.js-globalMenuArea');

  const resetMenuHeight = () => {
    globalMenuArea.style.height = 'auto';
    const newHeight = globalMenuArea.scrollHeight + 'px';
    setTimeout(() => {
      globalMenu.style.height = newHeight;
    }, 0);
  };

  const openMenu = (id) => {

    const target = document.querySelector('#global_' + id);
    if (!target) return;

    globalMenu.classList.add('is-active');

    const globalMenuContent = document.querySelectorAll('.js-globalMenuContent');
    globalMenuContent.forEach(content => {
      content.classList.remove('is-view');
    });
    target.classList.add('is-view');

    resetMenuHeight();

    setTimeout(() => {
      globalMenu.style.opacity = 1;
    }, 50);
  }

  const closeMenu = () => {
    globalMenu.classList.remove('is-active');

    const globalMenuContent = document.querySelectorAll('.js-globalMenuContent');
    globalMenuContent.forEach(content => {
      content.classList.remove('is-view');
    });

    globalMenu.style.height = 0;

    setTimeout(() => {
      globalMenu.style.opacity = 0;
    }, 50);
  }

  globalMenuTrigger.forEach(trigger => {
    trigger.addEventListener('mouseover', () => {
      const target_id = trigger.dataset.target;
      if(target_id) {
        openMenu(target_id);
      } else {
        closeMenu();
      }
    });
  });

  header.addEventListener('mouseleave', closeMenu);
}