export function formDate() {
  const formDate = document.querySelector('.js-formDate');

  if (!formDate) return;

  const urlParams = new URLSearchParams(window.location.search);
  const eventDate = urlParams.get('event-date');

  // イベントの日付が取得できていなければ終了
  if (!eventDate) return;

  // ラジオボタンを全て取得
  const radioButtons = document.querySelectorAll('input[name="event-date"]');

  radioButtons.forEach(radio => {
    // ラジオボタンのvalue値から、年月日を取得してYYYY-MM-DD形式に変換
    let radioValueDate = radio.value
      .replace(/年/, '-')
      .replace(/月/, '-')
      .replace(/日.*$/, ''); // 「日」以降を削除してYYYY-MM-DDに変換
    
    // 月と日が一桁の場合に0を付け加える
    const [year, month, day] = radioValueDate.split('-');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

    // 取得したURLパラメータと一致する場合、チェックを入れる
    if (formattedDate === eventDate) {
      radio.checked = true;
    }
  });

}